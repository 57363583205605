<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form ref="formRef" name="register" :rules="formRules" :model="formState" @finish="handleSubmit" layout="vertical">
        <img :style="{ width: '210px' }" :src="require('@/assets/img/logo.png')" alt="" />
        <sdHeading as="h3"> Create your Gamii account</sdHeading>
        <a-form-item label="Company Name" name="company_name">
          <a-input v-model:value="formState.company_name" placeholder="Enter company name" />
        </a-form-item>
        <a-row :gutter="25">
          <a-col :xs="24" :lg="12">
            <a-form-item label="First Name" name="first_name">
              <a-input v-model:value="formState.first_name" placeholder="Enter first name" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :lg="12">
            <a-form-item label="Last Name" name="last_name">
              <a-input v-model:value="formState.last_name" placeholder="Enter last name" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item name="email" label="Email Address">
          <a-input type="email" v-model:value="formState.email" placeholder="name@example.com" />
        </a-form-item>
        <a-form-item label="Password" name="password">
          <a-input type="password" v-model:value="formState.password" placeholder="Enter Password" />
        </a-form-item>
        <a-form-item>
          <sdButton class="btn-create" htmlType="submit" type="primary" size="large">
            Get Started
          </sdButton>
        </a-form-item>
        <p class="auth-tnc text-center">By creating an account, you accept our<a href="https://gamii.io/terms/" target="_blank"><span class="color-primary"> Terms of Service </span></a>and<a href="https://gamii.io/privacy/" target="_blank"><span class="color-primary"> Privacy Policy</span></a></p>
        <p class="form-divider">
          <span>Or</span>
        </p>
        <p class="text-center">Already have an account? <router-link to="/portal/login">Sign In</router-link></p>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { API } from "@/services/api";
import { AuthWrapper } from './style';
import Notification from "@/services/Notification";

export default {
  name: 'SignUp',
  components: { AuthWrapper },
  computed: {
    formRef: function() {
      return this.$refs.formRef;
    }
  },
  data() {
    return {
      formState: {
        first_name: "",
        last_name: "",
        company_name: "",
        email: "",
        password: ""
      },
      formRules: {
        first_name: [{
          required: true, trigger: 'blur',
          message: 'Please input first name',
        }],
        last_name: [{
          required: true, trigger: 'blur',
          message: 'Please input last name',
        }],
        company_name: [{
          required: true, trigger: 'blur',
          message: 'Please input company name',
        }],
        email: [{
          required: true, trigger: 'blur',
          message: 'Please input email',
        }, {
          type: 'email', trigger: 'blur',
          message: 'Please input valid email',
        }],
        password: [{
          required: true, trigger: 'blur',
          message: 'Please input password',
        }]
      },
    };
  },
  methods: {
    async handleSubmit() {
      const { replace, currentRoute } = this.$router;
      const ini = currentRoute.value.path.split("/")[1];
      try {
        await this.formRef.validate();
        const res = await API.register(this.formState, ini);
        const { status, message = "" } = res.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Register Success" : "Register Error",
          });
        }
        if (status) replace({path: `/${ini}/login`});
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error", title: "Error"});
        }
      }
    }
  },
};
</script>
